<template>
    <div id="homePage" style="position: relative;">
        <RemAdjuster></RemAdjuster>
        <p_header style="max-width: 1920px;margin: 0 auto;z-index: 9;"></p_header>

        <el-container>
            <el-main>
                <router-view/>
            </el-main>
        </el-container>

        <page_document></page_document>
    </div>
</template>

<script>
import p_header from "@/components/p_header/index.vue";
import RemAdjuster from "@/components/RemAdjuster/index.vue";
import page_document from "@/components/p_document/index.vue";

export default {
    name: 'Layout',
    components: {page_document, p_header, RemAdjuster},
    methods: {}
}
</script>

<style lang="less" scoped>
.el-main {
    padding: 0 !important;
    margin: 0 !important;
}
</style>
