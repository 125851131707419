import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/Layout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    children: [
      {
        path: '/', // 子路由的路径
        name: 'home',
        component: () => import('@/views/home') // 引入子路由的组件
      },
      {
        path: '/openClass',
        name: 'openClass',
        component: () => import('@/views/train/openClass') // 引入子路由的组件
      }
    ]
  },{
    path: '/mine',
    name: 'mine',
    component: () => import('@/views/mine') // 引入子路由的组件
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
