<template>
    <div class="navbar">
        <ul class="menu">
            <li
                class="menu-item"
                v-for="(item, index) in menuItems"
                :key="item.name"
                @click="selectMenuItem(index)"
            >
                <a
                    href="/"
                    :class="{ active: selectedIndex === index }"
                >{{ item.name }}
                    <i class="el-icon-arrow-down"></i>
                </a>

                <!-- 只有当 submenu 存在时才显示下拉框 -->
                <ul class="submenu" v-if="item.submenu && item.submenu.length">
                    <li
                        v-for="subItem in item.submenu"
                        :key="subItem.name"
                        class="submenu-item"
                    >
                        <div class="submenu-item-name" @click="gopath(subItem)">{{ subItem.name }}</div>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            menuItems: [
                {name: '首页', path: '/'},
                {
                    name: 'buildingSMART中国',
                    submenu: [
                        {
                            name: '关于我们',
                            path: ''
                        }, {
                            name: '会员单位',
                            path: ''
                        }, {
                            name: '新闻动态',
                            path: ''
                        }, {
                            name: '活动及报名',
                            path: ''
                        }
                    ]
                },
                {name: '标准',
				 submenu: [
				    {
				        name: '行业基础类',
				        path: ''
				    }, {
				        name: '信息交付说明',
				        path: ''
				    }, {
				        name: 'BIM协同格式',
				        path: ''
				    }
				]
				},
                {name: '服务',
				submenu: [
				    {
				        name: 'buildingSMART数据字典',
				        path: ''
				    }, {
				        name: 'IFC数据验证',
				        path: ''
				    }
				]
				},
                {name: '资源'},
                {name: '大奖赛',
				submenu: [
				    {
				        name: 'openBIM国际大奖赛报名',
				        path: ''
				    }, {
				        name: '国际大奖赛往年获奖案例',
				        path: ''
				    }
				]
				},
                {
                    name: '培训与人员评价',
                    submenu: [
                        {
                            name: '公开课',
                            path: '/openClass'
                        }, {
                            name: '会员单位',
                            path: ''
                        }, {
                            name: '新闻动态',
                            path: ''
                        }, {
                            name: '活动及报名',
                            path: ''
                        }
                    ]
                },
                {name: '项目与企业认证'}
            ],
            selectedIndex: null // 用于跟踪选中的菜单项
        };
    },
    methods: {
        selectMenuItem(index) {
            this.selectedIndex = index;
        },
        gopath(subItem) {
            if (subItem.path) {
                this.$router.push(subItem.path)
            }
        }
    }
};
</script>

<style scoped>
.navbar {
    background-color: #fff;
    padding: 0.625rem;
}

.menu {
    list-style-type: none;
    display: flex;
    padding-left: 0; /* 去除左边的默认内边距 */
    justify-content: flex-start;
}

.menu-item {
    position: relative;
    margin-right: 0.5rem;
}

.menu-item a {
    text-decoration: none;
    color: black;
    font-size: 1.125rem;
    padding: 0.625rem;
    cursor: pointer;
}

.menu-item a.active {
    color: purple; /* 选中时文字变为紫色 */
}

.menu-item:hover a {
    color: purple; /* 悬浮时文字变为紫色 */
}

.submenu {
    display: none;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #ffffff;
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 99;
    min-width: 8rem;
    border-radius: 5px;
    border: 1px solid #E4E7ED;
}

.submenu-item .submenu-item-name {
    text-decoration: none;
    cursor: pointer;
    color: black !important; /* 下拉框默认文字为黑色 */
    padding: 0.625rem;
    display: block;
    transition: background-color 0.3s ease;
}

.submenu-item .submenu-item-name:hover {
    background-color: #eee; /* 悬浮时背景色变浅灰色 */
    color: purple !important; /* 悬浮时文字变为紫色 */
}

.menu-item:hover .submenu {
    display: block;
}
</style>
