<template>
	<div style="width: 100%;">
		<div style="display: flex;align-items: center;width: 87.5rem;margin: 0 auto">
			<img src="@/assets/img/logo.png" style="width: 12.5rem;height: 2.375rem;margin-right: 2rem">
			<p_menu></p_menu>
			
			<div style="color: #189db6;font-size: 18px;font-weight: bold;cursor: pointer;" v-if="userInfo.realName" @click="goMine"> {{ userInfo.realName }}</div>
			
			<div v-else style="display: flex;align-items: center;gap: 1rem;">
				<i class="el-icon-search" style="font-size: 1.25rem;cursor: pointer"></i>
				<i @click="goMine" class="el-icon-user-solid" style="font-size: 1.25rem;cursor: pointer"></i>
			</div>
		</div>
	</div>
</template>

<script>
import p_menu from "@/components/p_menu/index.vue";
import axios from 'axios'

export default {
    components: {
	  p_menu
	},
	props: {},
	data() {
	  return {
	  	token: '',
	  	userInfo:{
		  realName:''
		}
      }
	},
	created() {
	  this.token = this.$cookies.get('token');
	  if (this.token) {
	  	this.fetchDataWithHeaders()
	  }
	},
	mounted() {

	},
	methods: {
		fetchDataWithHeaders() {
			const headers = {
			        'Token': this.token,
			        'Content-Type': 'application/json'
			      };
			this.$axios({
				method: "post",
				url: "https://test.buildingsmartchina.org/api/sys/user/info",
				data: {"token": this.token},
				headers: headers
			}).then((res) => {
				this.userInfo = res.data.data
			})
		},
		goMine() {
			window.open('https://test.buildingsmartchina.org/web/uc', '_blank');
		}
	}
}
</script>

<style scoped>

</style>