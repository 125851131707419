<template>
    <div style="display: none"></div> <!-- 该组件没有任何视图输出 -->
</template>

<script>
export default {
    name: 'RemAdjuster',
    mounted() {
        const setRem = () => {
            const baseSize = 16; // 设置基础字号，可以根据需求调整
            const designWidth = 1920; // 设计稿的宽度（针对 PC 端）
            const minWidth = 300; // 设定最小宽度
            const maxWidth = 2560; // 设定最大宽度

            const currentWidth = Math.min(Math.max(document.documentElement.clientWidth, minWidth), maxWidth);
            const scale = currentWidth / designWidth;

            // 设置最大放大比例，避免超宽屏幕或过窄屏幕字体过大或过小
            document.documentElement.style.fontSize = `${baseSize * scale}px`;
        };

        // 页面加载时调用 setRem
        setRem();

        // 监听窗口变化
        window.addEventListener('resize', setRem);

        // 保存到实例，方便在 beforeUnmount 中移除事件
        this.setRem = setRem;
    },
    beforeUnmount() {
        // 组件销毁时，移除事件监听器
        window.removeEventListener('resize', this.setRem);
    }
};
</script>

<style scoped>
/* 组件无任何样式 */
</style>
