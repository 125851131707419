<template>
    <div class="document-box">
        <div class="document-item" v-for="(item,index) in list">
            <div class="document-title">
                {{ item.title }}
            </div>

            <div class="document-text">
                <div class="text-item" v-for="(jtem,jndex) in item.content" @click="goDoc(item,jtem)">
                    {{ jtem.title }}
                </div>
            </div>
        </div>

        <div class="document-item">
            <div class="document-title">
                联系我们
            </div>
            <div>
                <div style="color: #ffffff;font-size: 1rem">
                    <i class="el-icon-message" style="color: #67C23A"></i>
                    <div>office@buildingsmartchina.org</div>
                </div>
                <div style="display: flex;gap: 1.25rem;margin-top: 4rem">
                    <div style="display: flex;align-items: center;flex-direction: column;justify-content: center">
                        <img src="../../assets/img/ewm.png" style="width: 5.625rem;height: auto" alt=""/>
                        <div style="color: white;font-size: 0.625rem;margin-top: 0.625rem">buildingSMART中国课堂公众号</div>
                    </div>

                    <div style="display: flex;align-items: center;flex-direction: column;justify-content: center">
                        <img src="../../assets/img/zgewm.png" style="width: 5.625rem;height: auto" alt=""/>
                        <div style="color: white;font-size: 0.625rem;margin-top: 0.625rem">buildingSMART中国公众号</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            list: [
                {
                    title: "buildingSMART中国",
                    content: [
                        {
                            title: "关于我们"
                        }, {
                            title: "会员单位"
                        }, {
                            title: "新闻动态"
                        }, {
                            title: "活动及报名"
                        }
                    ]
                }, {
                    title: "标准",
					content: [
					    {
					        title: "行业基础类"
					    }, {
					        title: "信息交付说明"
					    }, {
					        title: "BIM协同格式"
					    }
					]
                }, {
                    title: "服务",
					content: [
					    {
					        title: "buildingSMART数据字典"
					    }, {
					        title: "IFC数据验证"
					    }
					]
                }, {
                    title: "资源",
                }, {
                    title: "大奖赛",
					content: [
					    {
					        title: "openBIM国际大奖赛报名"
					    }, {
					        title: "国际大奖赛往年获奖案例"
					    }
					]
                }, {
                    title: "培训与人员评价",
					content: [
					    {
					        title: "openBIM公开课"
					    }, {
					        title: "buildingSMART国际培训体系"
					    }, {
					        title: "buildingSMART国内培训体系"
					    }
					]
                }
            ]
        }
    },
    created() {
    },
    mounted() {

    },
    methods: {
        goDoc() {

        }
    }
}
</script>

<style scoped lang="less">
.document-box {
    padding:1.25rem 5rem;
    min-height: 18.75rem;
    border-bottom: 1px solid #2D3045;
    background: #161931;
    display: flex;
    justify-content: center;
    gap: 7rem;

    .document-item {
        .document-title {
            color: #ffffff;
            font-size: 1rem;
            margin: 0.625rem 0 1.5rem 0;
        }

        .document-text {

            .text-item {
                margin: 10px 0px;
                color: #999999;
                font-size: 0.875rem;
                cursor: pointer;
            }

            .text-item:hover {
                color: #ffffff;
            }
        }
    }
}
</style>